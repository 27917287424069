import React from "react";
import * as styles from "../card/card.module.css";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {micro, small} from "../typography.module.css";

const Card = ({painting}) => {
  return (
    <div className={styles.item}>
      <GatsbyImage alt={painting.mainImage.alt}
                   image={getImage(painting.mainImage.asset.gatsbyImageData)}
                   className={styles.image}/>
      <div className={small}>
        <em><strong>{`${painting.title} `}</strong></em> ({painting.year})
      </div>
      <div>
        <span className={small}>{painting.medium.medium} </span>
        <span className={micro}>&#183; {painting.size}</span>
      </div>
    </div>
  );
}

export default Card;
