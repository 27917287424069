import React from "react";
import {graphql} from "gatsby";
import {filterOutDocsPublishedInTheFuture, filterOutDocsWithoutSlugs, mapEdgesToNodes} from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Gallery from "../components/gallery/gallery";
import "../styles/noscroll.css";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
    }
    paintings: allSanityPainting(
      limit: 6
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          mainImage {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
          title
          medium {
            medium
          }
          slug {
            current
          }
          year
          size
        }
      }
    }
  }
`;

const IndexPage = props => {
  const {data, errors} = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    );
  }

  const site = (data || {}).site;
  const paintings = (data || {}).paintings
    ? mapEdgesToNodes(data.paintings)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords}/>
      <Gallery paintings={paintings}/>
    </Layout>
  );
};

export default IndexPage;
