import React, {useRef} from "react";

import * as styles from "./gallery.module.css";
import Card from "../card/card";
import {CgArrowLongLeft, CgArrowLongRight} from "react-icons/cg";

const Gallery = ({paintings}) => {
  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    console.log("scrolling", scrollOffset);
    ref.current.scrollLeft += scrollOffset;
  }
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <CgArrowLongLeft className={styles.arrow} onClick={() => scroll(-300)}/>
        <CgArrowLongRight className={styles.arrow} onClick={() => scroll(300)}/>
      </div>
      <div className={styles.horizontalView} ref={ref}>
        {paintings && paintings.map(painting => (
          <Card key={painting.id} painting={painting}/>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
